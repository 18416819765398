define('application/options',[],function () {
  return {
    moveToFragment: {
      staticHeaderSelector: ".js-move-to-fragment.header"
    },
    owlCarousel: {
      autoplay: true,
      loop: true,
      items: 1,
      animateOut: "fadeOut",
    },
  }
});
