define('application/styledForms',[
  "application/styledForms/multipleSelect",
  "application/styledForms/dropDownSelect",
], function (
  styledFormsMultipleSelect,
  styledFormsDropDownSelect
) {
  return function ($document) {
    styledFormsMultipleSelect($document);
    styledFormsDropDownSelect($document);
  }
});
