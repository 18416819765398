define('application/main',[
  "application/options",
  "i18n!nls/text",
  "jquery",
  "picture-polyfill",
  "application/moveToFragment",
  "application/styledForms",
  "application/menu",
  "application/carousel",
  "application/currency",
  "application/filters",
  "application/thumbnails"
], function (
  appOptions,
  text,
  $,
  picturePolyfill,
  appMoveToFragment,
  appStyledForms,
  appMenu,
  appCarousel,
  appCurrency,
  appFilters,
  appThumbnails
) {
  var $window = $(window);
  var $document = $(document);
  var $html = $("html");
  var $body = $("body");

  var locale = $html.attr("lang");

  appMoveToFragment($document);
  appStyledForms($document);
  appMenu($document);
  appCarousel($document);
  appCurrency($document);
  appFilters($document);
  appThumbnails($document);

  $document.on("app.domset", function (event, data) {
    data.dom.find(".js-product-cart-edit").each(function () {
      var $this = $(this);

      $this.on("click", function () {
        $(".js-product-cart-edit-id-" + $this.data("product-cart-edit-target")).fadeToggle();
      });
    });
  });

  $document.on("app.domset", function (event, data) {
    data.dom.find("select[multiple=multiple]").each(function () {
      var $this = $(this);

      $this.find("option").on("mousedown", function (event) {
        event.preventDefault();

        var $this = $(this);
        var originalScrollTop = $this.parent().scrollTop();

        $this.prop("selected", !$this.prop("selected"));
        $this.parent().focus();

        setTimeout(function () {
          $this.parent().scrollTop(originalScrollTop);
        }, 0);

        $this.trigger("input");

        return false;
      });
    });
  });

  $document.trigger("app.domset", [{dom: $body}]);

  // run it when the page is already scrolled on refresh so the events based on scrolling can fire at load
  $window.trigger("scroll");

  // run it when the page is loaded so the events based on resizing can fire at load
  $window.trigger("resize");
});
