define('application/currency',[],function () {
  return function ($document) {
    $document.on("app.domset", function (event, data) {
      data.dom.find(".js-currency").each(function () {
        var $this = $(this);

        $this.on("input", function () {
          $this.closest("form").trigger("submit");
        });
      });
    });
  }
});
