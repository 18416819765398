define('application/filters',[],function () {


  return function ($document) {


    $document.on("app.domset", function (event, data) {
      var $toggle = data.dom.find(".js-filters-toggle").each(function () {
        var $this = $(this);

        $this.on("click", function () {
          $(".js-filters").fadeToggle();

          if ($this.text() === "+") {
            $this.html("-");
          } else {
            $this.html("+");
          }
        });
      });

      var $form = data.dom.find(".js-filters-form");

      $form.each(function () {


        $form.on("input", function () {
          var $products = $(".js-filters-product");
          $products.css("display", "block");
          //$products.fadeIn();

          var filtersIndex = $form.find("#filters_index").val().toLowerCase();

          var filtersPrice = $form.find("#filters_price").val().split(',');
          var filtersPriceMin = filtersPrice[0];
          var filtersPriceMax = filtersPrice[1];

          var filtersCategories = $form.find("#filters_categories").val();

          var filtersFeatureValues = [];
          $form.find('[id^="filters_feature"]').each(function () {
            Array.prototype.push.apply(filtersFeatureValues, $(this).val());
          });

          $products.each(function () {
            var $product = $(this);

            if (filtersIndex.length > 0 && $product.data("filters-index").toLowerCase().indexOf(filtersIndex) === -1) {
              $product.css("display", "none");
              //$product.fadeOut();
              return;
            }

            if (typeof filtersPriceMax !== "undefined" && $product.data("filters-price") < filtersPriceMin) {
              $product.css("display", "none");
              //$product.fadeOut();
              return;
            }

            if (typeof filtersPriceMax !== "undefined" && $product.data("filters-price") > filtersPriceMax) {
              $product.css("display", "none");
              //$product.fadeOut();
              return;
            }

            if (filtersCategories.length > 0 && filtersCategories.indexOf($product.data("filters-category")) === -1) {
              $product.css("display", "none");
              //$product.fadeOut();
              return;
            }

            if (filtersFeatureValues.length > 0) {
              var hide = true;

              for (var i = 0; i < filtersFeatureValues.length; i++) {
                if ($product.data("filters-features").indexOf(filtersFeatureValues[i]) > -1) {
                  hide = false;
                  break;
                }
              }

              if (hide === true) {
                $product.css("display", "none");
              }
            }
          });
        });
      });
    });
  }
});
