define('application/thumbnails',[],function () {
  return function ($document) {
    $document.on("app.domset", function (event, data) {
      var $thumbnails = data.dom.find(".js-thumbnails");
      var $thumbnailPlaceholder = data.dom.find(".js-thumbnails-placeholder");

      $thumbnails.each(function () {
        var $this = $(this);

        $this.on("click", function (event) {
          event.preventDefault();

          $thumbnailPlaceholder.fadeTo(200, 0, function () {
            $thumbnailPlaceholder.html($this.html());
            $thumbnailPlaceholder.fadeTo(200, 1, function () {});
          });
        });
      });
    });
  }
});
