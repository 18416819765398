define('application/styledForms/multipleSelect',["i18n!nls/text"], function (text) {
  var classPrefix = "styled-forms-multiple-select";

  var createPlaceholder = function ($inputField) {
    var $placeholder = $("<div class=\"" + classPrefix + "__placeholder\"></div>");

    if ($inputField.val().length > 0) {
      var labels = [];
      $inputField.find(":selected").each(function () {
        labels.push($(this).text());
      });

      $("<p class=\"" + classPrefix + "__placeholder__values\">" + labels.join(", ") + "</p>").appendTo($placeholder);
      $("<span class=\"sprites svg-filters-close-desktop\"></span><span class=\"sprites svg-filters-close-mobile\"></span>").appendTo($placeholder);
    } else {
      $("<p class=\"" + classPrefix + "__placeholder__empty\">" + text.all + "</p>").appendTo($placeholder);
      $("<span class=\"sprites svg-filters-open-desktop\"></span><span class=\"sprites svg-filters-open-mobile\"></span>").appendTo($placeholder);
    }

    $placeholder.on("click", function () {
      $placeholder.parent().toggleClass("active");
      $("<div class=\"js-mask\"></div>").on("click", function () {
        $inputField.parent().find("." + classPrefix).toggleClass("active");
        $(this).remove();
      }).appendTo($("body"));
    });

    return $placeholder;
  };

  var createList = function ($inputField) {
    var $list = $("<ul class=\"" + classPrefix + "__dropdown__list\"></ul>");

    $inputField.find("option").each(function () {
      var $this = $(this);

      var $item = $("<li class=\"" + classPrefix + "__dropdown__list__element\" data-styled-forms-multiple-select-value=\"" + $this.val() + "\"></li>");

      $("<span class=\"" + classPrefix + "__dropdown__list__element__checkbox" + ($this.is(":selected") ? " active" : "") + "\"></span>").appendTo($item);
      $("<span class=\"" + classPrefix + "__dropdown__list__element__text\">" + $this.text() + "</span>").appendTo($item);

      $item.on("click", function () {
        $inputField.find("option[value=" + $this.val() + "]").trigger("mousedown");
      });

      $item.appendTo($list);
    });

    return $list;
  };

  var createDropdown = function ($inputField) {
    var dropdown = $("<div class=\"" + classPrefix + "__dropdown\"></div>");

    var $choose = $("<p class=\"" + classPrefix + "__dropdown__text\">" + text.choose + "</p>");

    $choose.on("click", function () {
      $inputField.parent().find("." + classPrefix).toggleClass("active");
      $(".js-mask").remove();
    });

    $choose.appendTo(dropdown);

    createList($inputField).appendTo(dropdown);

    var $clearSelection = $("<p class=\"" + classPrefix + "__dropdown__clear-selection\">" + text.clearSelection + "</p>");

    $clearSelection.on("click", function () {
      $inputField.val([]).trigger("input");
    });

    $clearSelection.appendTo(dropdown);

    return dropdown;
  };

  return function ($document) {
    $document.on("app.domset", function (event, data) {
      data.dom.find(".js-styled-forms-multiple-select").each(function () {
        var $inputField = $(this);
        var $styledField = $("<div class=\"" + classPrefix + "\"></div>");

        createPlaceholder($inputField).appendTo($styledField);
        createDropdown($inputField).appendTo($styledField);

        $styledField.insertAfter($inputField);

        $inputField.on("input", function () {
          $inputField.parent().find("." + classPrefix + "__placeholder").replaceWith(createPlaceholder($inputField));
          //$inputField.parent().find("." + classPrefix + "__dropdown").replaceWith(createDropdown($inputField));

          var $dropdownOld = $inputField.parent().find("." + classPrefix + "__dropdown");
          var $dropdownNew = createDropdown($inputField);

          var scroll = $dropdownOld.find("ul").scrollTop();
          $dropdownOld.replaceWith($dropdownNew);
          $dropdownNew.find("ul").scrollTop(scroll);
        });
      });
    });
  }
});
