define('application/menu',[],function () {
  var setToCenter = function ($menu) {
    var $client = $menu.find(".js-menu-client");
    var $social = $menu.find(".js-menu-social");
    var languages = $menu.find(".js-menu-languages");
    var width = $client.outerWidth(true) + $social.outerWidth(true) + languages.outerWidth(true);

    $("#js-menu").remove();
    $("<style id=\"js-menu\">@media (min-width: 1580px) and (max-width: 1779px) {.js-menu-main:before {width: " + (width - 140) + "px;}}@media (min-width: 1780px) {.js-menu-main:before {width: " + width + "px;}}</style>").appendTo("head");
  };

  return function ($document) {
    $document.on("app.domset", function (event, data) {
      var $menu = data.dom.find(".js-menu");

      $menu.each(function () {
        $(window).on("resize", function () {
          setToCenter($menu);
        });

        var $body = $("body");

        data.dom.find(".js-menu-trigger").on("click", function () {
          $menu.toggleClass("active");
          $body.toggleClass("js-menu-active");
        });

        $menu.on("click", function () {
          $menu.removeClass("active");
          $body.removeClass("js-menu-active");
        });
      });
    });

    return this;
  }
});
